.typing-loading {
    display: flex;
    transition: all 0.8s;
}

.typing-loading div {
    margin-left: 0.3rem;
    background-color: rgb(34, 34, 34);
    box-shadow: inset 2px 2px 10px black;
    border-radius: 100%;
    height: .3rem;
    width: .3rem;
}

.typing-loading-box1 {
    animation: brighten 1.2s infinite;
}

.typing-loading-box2 {
    animation: brighten 1.2s infinite;
    animation-delay: .2s;
}

.typing-loading-box3 {
    animation: brighten 1.2s infinite;
    animation-delay: .4s;
}

@keyframes brighten {
    100% {
        background-color: rgb(165, 165, 165);
        box-shadow: none;
    }
}
