.img-loading-circle {
    border: 4px solid #3F51B5;
    border-radius: 50%;
    border-right-color: transparent;
    animation: rotating-border 2s linear infinite;
    box-shadow: 0 0 20px #3F51B5 inset;
}

@keyframes rotating-border {
    100% {
        transform: rotate(360deg);
    }
}