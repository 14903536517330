/* Dialog Transparent */
.transparent {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

/* Dialog Container */
.dialog {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate( -50%, -50% ) scale( 0 );
    opacity: 0;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    border-radius: 1.5rem;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.dialog.active {
    opacity: 1;
    animation: displayDialog 0.1s ease-in-out .2s forwards;
}
@keyframes displayDialog {
    to {
        opacity: 1;
        transform: translate( -50%, -50% ) scale( 1 );
    }
}

/* Dialog Header */
.dialog .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding: 5px 7px 5px 12px;
}
.dialog .header .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #2d2d2d;
    gap: 6px;
}
.dialog .header .title label {
    font-weight: bold;
    font-size: 15px;
}
.dialog .header button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border: 1px solid darkgray;
    border-radius: 50%;
    font-size: 10px;
    color: dimgrey;
    cursor: pointer;
}

/* Dialog Content */
.dialog .content {
    max-height: 0;
    overflow: hidden;
    border-top: 1px solid lightgrey;
}
.dialog .content.loaded {
    animation: expandContent 0.2s ease-in-out .3s forwards;
}
@keyframes expandContent {
    to {
        max-height: 500px;
    }
}

/* Dialog Loader Header */
.dialog .loader {
    display: flex;
    transition: all 0.4s;
}
.dialog .loader div {
    margin-left: 0.3rem;
    background-color: rgb(34, 34, 34);
    box-shadow: inset 2px 2px 10px black;
    border-radius: 100%;
    height: .5rem;
    width: .5rem;
}
.dialog .box-load1 {
    animation: brighten 1.2s infinite;
}
.dialog .box-load2 {
    animation: brighten 1.2s infinite;
    animation-delay: .2s;
}
.dialog .box-load3 {
    animation: brighten 1.2s infinite;
    animation-delay: .4s;
}
.dialog .loader.loaded {
    display: none;
}
@keyframes brighten {
    100% {
        background-color: rgb(165, 165, 165);
        box-shadow: none;
    }
}