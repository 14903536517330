:root {

    /* dark */
    --dark-card: #1C2227;
    --dark-background: #171717;
    --dark-text-title: #fff;
    --dark-text-details: #a3a3a3;
    --dark-text-article: #e5e5e5;
    --dark-text-list-items: rgb( 229 229 229 );
    --dark-pills-bg: #3c649f;
    --dark-hover-bg: #4A5568;
    --dark-active-bg: #9299a4;
    --dark-list-border: #4a5568;
    --dark-major-border: #cbd5e0;
    --dark-active-text: #ffffff;
    --dark-hover-text: #ffffff;
    --dark-textarea-bg: rgba(163,163,163, 0.2);
    --dark-textarea-text: #ffffff;
    --dark-textarea-border: #d2d6dc;
    --dark-loading-base-color: #5a5a5a;
    --dark-loading-highlight-color: #303030;

    /* light */
    --light-card: #fcfcfc;
    --light-background: #e7e7e7;
    --light-text-title: #191919;
    --light-text-details: #7f7f7f;
    --light-text-article: #2d2d2d;
    --light-text-list-items: #323232;
    --light-pills-bg: #0096fe;
    --light-hover-bg: #d1d1d1;
    --light-active-bg: #e0e0e0;
    --light-list-border: #d1d1d1;
    --light-major-border: #c1c1c1;
    --light-active-text: #191919;
    --light-hover-text: #515151;
    --light-textarea-bg: rgba(163,163,163, 0.2);
    --light-textarea-text: #191919;
    --light-textarea-border: #d2d6dc;
    --light-loading-base-color: #5a5a5a;
    --light-loading-highlight-color: #303030;

    /* sky */
    --sky-card: #dcf0fa;
    --sky-background: #84cdee;
    --sky-text-title: #191919;
    --sky-text-details: #4c4c4c;
    --sky-text-article: #2d2d2d;
    --sky-text-list-items: #323232;
    --sky-pills-bg: #0096fe;
    --sky-hover-bg: #6ed5ee;
    --sky-active-bg: #2acaea;
    --sky-list-border: #a3a3a3;
    --sky-major-border: #b2b2b2;
    --sky-active-text: #ffffff;
    --sky-hover-text: #ffffff;
    --sky-textarea-bg: rgba(163,163,163, 0.3);
    --sky-textarea-text: #191919;
    --sky-textarea-border: #d2d6dc;
    --sky-loading-base-color: #5a5a5a;
    --sky-loading-highlight-color: #303030;

    /* default */
    --card: var( --dark-card );
    --background: var( --dark-background );
    --text-title: var( --dark-text-title );
    --text-details: var( --dark-text-details );
    --text-article: var( --dark-text-article );
    --text-list-items: var( --dark-text-list-items );
    --pills-bg: var( --dark-pills-bg );
    --hover-bg: var( --dark-hover-bg );
    --active-bg: var( --dark-active-bg );
    --list-border: var( --dark-list-border );
    --major-border: var( --dark-major-border );
    --active-text: var( --dark-active-text );
    --hover-text: var( --dark-hover-text );
    --textarea-bg: var( --dark-textarea-bg );
    --textarea-text: var( --dark-textarea-text );
    --textarea-border: var( --dark-textarea-border );
    --base-color: var( --dark-loading-base-color );
    --highlight-color: var( --dark-loading-highlight-color );
}

/* Page Components */
body.dark, 
html.dark {
    --card: var( --dark-card );
    --background: var( --dark-background );
    --text-title: var( --dark-text-title );
    --text-details: var( --dark-text-details );
    --text-article: var( --dark-text-article );
    --text-list-items: var( --dark-text-list-items );
    --pills-bg: var( --dark-pills-bg );
    --hover-bg: var( --dark-hover-bg );
    --active-bg: var( --dark-active-bg );
    --list-border: var( --dark-list-border );
    --major-border: var( --dark-major-border );
    --active-text: var( --dark-active-text );
    --hover-text: var( --dark-hover-text );
    --textarea-bg: var( --dark-textarea-bg );
    --textarea-text: var( --dark-textarea-text );
    --textarea-border: var( --dark-textarea-border );
    --base-color: var( --dark-loading-base-color );
    --highlight-color: var( --dark-loading-highlight-color );
}
body.light, 
html.light {
    --background: var( --light-background );
    --card: var( --light-card );
    --text-title: var( --light-text-title );
    --text-details: var( --light-text-details );
    --text-article: var( --light-text-article );
    --text-list-items: var( --light-text-list-items );
    --pills-bg: var( --light-pills-bg );
    --hover-bg: var( --light-hover-bg );
    --active-bg: var( --light-active-bg );
    --list-border: var( --light-list-border );
    --major-border: var( --light-major-border );
    --active-text: var( --light-active-text );
    --hover-text: var( --light-hover-text );
    --textarea-bg: var( --light-textarea-bg );
    --textarea-text: var( --light-textarea-text );
    --textarea-border: var( --light-textarea-border );
    --base-color: var( --light-loading-base-color );
    --highlight-color: var( --light-loading-highlight-color );
}
body.sky, 
html.sky {
    --background: var( --sky-background );
    --card: var( --sky-card );
    --text-title: var( --sky-text-title );
    --text-details: var( --sky-text-details );
    --text-article: var( --sky-text-article );
    --text-list-items: var( --sky-text-list-items );
    --pills-bg: var( --sky-pills-bg );
    --hover-bg: var( --sky-hover-bg );
    --active-bg: var( --sky-active-bg );
    --list-border: var( --sky-list-border );
    --major-border: var( --sky-major-border );
    --active-text: var( --sky-active-text );
    --hover-text: var( --sky-hover-text );
    --textarea-bg: var( --sky-textarea-bg );
    --textarea-text: var( --sky-textarea-text );
    --textarea-border: var( --sky-textarea-border );
    --base-color: var( --sky-loading-base-color );
    --highlight-color: var( --sky-loading-highlight-color );
}