
body, html {
  background-color: var( --background );
  color: #f1f1f1;
}

input.disabled, button.disabled {
  background-color: gray;
  color: #f7f7f7;
}
.height-auto {
  height: fit-content;
}
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-absolute-animate {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.1s, opacity 0.1s;
}
.center-absolute-animate.active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.spinner {
  border: 5px solid rgba( 0, 0, 0 );
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.writing-mode-vertical-rl {
  writing-mode: vertical-rl;
}
.custom-hover {
  user-select: none;
  transition: background-color 0.5s;
  cursor: pointer;
}
button.custom-hover, input[type='button'].custom-hover {
  transition: background-color 0.3s;
  cursor: pointer;
}
.custom-hover.active,
.custom-hover:active {
  background-color: #9299a4 !important;
  transition: background-color 0.1s;
}
.custom-hover:hover {
  color: var( --hover-text );
  background-color: var( --hover-bg );
}
.custom-hover.active,
.custom-hover:active {
  background-color: var( --active-bg ) !important;
  transition: background-color 0.1s;
  color: var( --active-text );
}
.custom-border {
  border-color: var( --major-border);
}
.custom-border-list {
  border-color: var( --list-border );
}
.custom-textarea-border {
  border-color: var( --textarea-border );
}
.theme.active {
  border: 1px solid blue;
}
.dating-card {
  background: #333;
  width: 200px;
  height: 200px;
  position: relative;
  overflow:hidden;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.dating-card img {
  object-fit: cover;
}
.dating-card .title {
  background:#333;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  opacity: 0;
  padding: .5rem;
  font-weight: bolder;
  cursor: pointer;
  color: var( --text-title );
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.dating-card:hover img {
  opacity: .3;
}

.dating-card:hover .title {
  opacity: 1;
  margin-bottom: 65px;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

